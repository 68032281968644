import React from 'react';
import { Helmet } from 'react-helmet';
// import { useTranslation } from 'react-i18next';
import "./privacypolicy.css";
// import companyPolicyImage from "./company_policy.png";
import { Navbar } from '../../components';
import {Footer} from '../../containers';

// Specify the location of the worker script
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PrivacyPolicy = () => {

    // const { t } = useTranslation("global");

    return (
        <div className='App'>
            <Helmet>
                <title>Sonic Transcript Pricing Privacy Policy</title>
                <meta name='description' 
                      content='At Sonic Transcript, we respect the privacy rights of our users and
                        recognize the importance of protecting the personal information we collect
                        about you. Our Privacy Policy is designed to help you understand what
                        information we collect and how we use and share that information. This
                        Privacy Policy applies to our Websites and Services. By using or accessing
                        the Site or Services, you are accepting the practices described in this
                        Privacy Policy.'
                    />
                <meta name="keywords" content="transcription services, researchers, interviews, lectures, fast transcription, accurate transcription, google scholar" />
                <link rel="canonical" href="https://www.sonictranscript.com" />
                <link rel="icon" href="https://www.sonictranscript.com/favicon.ico" /> {/* Add this line */}
            </Helmet>
            <div className='bg__normal'>
                <Navbar />
                {/* <Header /> */}
            </div>
            <div className="policy-container">
                {/* <img src={companyPolicyImage} alt="Company Policy" className="policy-image" style={{ display: 'block', margin: '0 auto' }} /> */}
                <h1>Privacy Policy</h1>
                <div className='wordDoc'>
                    <meta httpEquiv="Content-Type" content="text/html; charset=windows-1252" />
                    <meta name="Generator" content="Microsoft Word 15 (filtered)" />
                    
                    <div className="WordSection1">
                        <p className="MsoNormal">
                        At Sonic Transcript, we respect the privacy rights of our users and
                        recognize the importance of protecting the personal information we collect
                        about you. Our Privacy Policy is designed to help you understand what
                        information we collect and how we use and share that information. This
                        Privacy Policy applies to our Websites and Services. By using or accessing
                        the Site or Services, you are accepting the practices described in this
                        Privacy Policy.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Definitions</u>
                        </b>
                        </p>
                        <ul>
                            <li><p className="MsoListParagraphCxSpFirst" style={{ textIndent: "0in" }}>
                            <span style={{ fontFamily: "Symbol" }}>
                                
                                <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            When we say "we", "us", "our" or "Sonic Transcript", we're referring to
                            SonicTranscript, a division of Northern Lighthouse LLC.
                            </p>
                            </li>
                            <li><p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "0in" }}>
                            <span style={{ fontFamily: "Symbol" }}>
                                
                                <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            "Websites" means Sonic Transcript's websites (including without limitation
                            www.sonictranscript.com and any successor URLs, mobile or localized
                            versions and related domains and subdomains), and the "Services" means
                            Sonic Transcript's products, web and mobile applications and services, in
                            each case in whatever format they may be offered now or in the future.{" "}
                            </p>
                            </li>
                            <li><p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "0in" }}>
                            <span style={{ fontFamily: "Symbol" }}>
                                
                                <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            The Websites and Services are collectively referred to herein as the
                            "Offerings."
                            </p>
                            </li>
                            <li><p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "0in" }}>
                            <span style={{ fontFamily: "Symbol" }}>
                                
                                <span style={{ font: '7.0pt "Times New Roman"' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </span>
                            "Customer" refers to any customer that has entered into an agreement with
                            Sonic Transcript to use Sonic Transcript's services.
                            </p>
                            </li>
                            <li>
                                <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: "0in" }}>
                                <span style={{ fontFamily: "Symbol" }}>
                                    
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                "Visitor" refers to anyone accessing the Sonic Transcript marketing
                                website at{" "}
                                <a href="https://www.sonictranscription.com/">www.sonictranscript.com</a>.
                                </p>
                            </li>
                            <li>
                                <p className="MsoListParagraphCxSpLast" style={{ textIndent: "0in" }}>
                                <span style={{ fontFamily: "Symbol" }}>
                                    
                                    <span style={{ font: '7.0pt "Times New Roman"' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                </span>
                                "Personal Information" refers to any information which may identify an
                                individual personally, such as first and last name, billing address, email
                                address, job role and employer, telephone number, etc.
                                </p>
                            </li>
                        </ul>
                        <p className="MsoNormal">
                        <b>
                            <i>
                            <span style={{ fontSize: "12.0pt", lineHeight: "115%" }}>&nbsp;</span>
                            </i>
                        </b>
                        </p>
                        <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                        <b>
                            <i>
                            <span style={{ fontSize: "24.0pt", lineHeight: "115%" }}>
                                Information we collect - Personal Information
                            </span>
                            </i>
                        </b>
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Website Visitors</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        When accessing the Websites as a Visitor, you may choose to interact with
                        the Websites in a way that results in you providing Personal Information
                        to Sonic Transcript, such as giving us your name, email address, phone
                        number and company name when contacting us with an inquiry or request to
                        demo Sonic Transcript.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        We only collect Personal Information from Visitors that is reasonable or
                        necessary to accomplish the purpose of your interaction with Sonic
                        Transcript as a Visitor. Any Personal Information provided by you as a
                        Visitor to the Website will be used only as described in this Privacy
                        Policy.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Customers of the Services</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        When registering as a Customer to use the Services, we may require or ask
                        you to provide certain personally identifiable information (these are
                        referred to below as your "Personal Contact Information"). The Personal
                        Contact Information that we require you to provide may include, but is not
                        limited to, the following:
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: "1in" }}>
                        E-mail
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: "1in" }}>
                        Full name
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: "1in" }}>
                        Company name
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: "1in" }}>
                        Job Title
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: "1in" }}>
                        &nbsp;
                        </p>
                        <p className="MsoNormal">
                        When purchasing the Services, we will require you to provide financial and
                        billing information, such as billing name and address, and credit card
                        number ("Billing Information"). When you make a purchase on the Offerings,
                        any credit card information you provide as part of your Billing
                        Information is collected and processed directly by our payment processor
                        Stripe through their Stripe Checkout service. We never receive or store
                        your full credit card information. The information we collect from a
                        Customer is used only in accordance with the Agreement and this Privacy
                        Policy.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        We may use your Personal Information to contact you with newsletters,
                        marketing or promotional materials and other information that may be of
                        interest to you. You may opt out of receiving any, or all, of these
                        communications from us by following the unsubscribe link or instructions
                        provided in any email we send.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Data</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        As part of the Services you are uploading files to our servers. This file
                        is referred to below as "Data". Some of this Data may be stored and
                        maintained on our servers. Specifically, you may upload spoken audio files
                        into our Services ("Audio Files"), and use our Services to create and
                        transmit written transcripts of such Audio Files ("Transcripts"). Data is
                        encrypted as part of the upload process. As a general matter, these files
                        only remains on our server in encrypted form long enough to be converted
                        from its original format to a text-based transcript and are automatically
                        deleted from our servers as soon as they have been successfully
                        transcribed. Transcripted data files do not remain on our services once
                        they have been downloaded in order to maximize the security and
                        confidentiality of the data.{" "}
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                        <b>
                            <i>
                            <span style={{ fontSize: "24.0pt", lineHeight: "115%" }}>
                                Usage and Analytics Information
                            </span>
                            </i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        As a Visitor to the Websites and as a Customer using our Services, we may
                        also collect information through the use of commonly-used
                        information-gathering tools, such as cookies, log files, and Web beacons.
                        Collectively, this information is referred to as "Usage and Analytics
                        Information."
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>
                            <span style={{ textDecoration: "none" }}>&nbsp;</span>
                            </u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>Cookies</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        We use standard browser cookies to allow Sonic Transcription to remember
                        details of a user session. For example, we may use cookies to make it
                        easier for the user to resume a recent session without re-entering their
                        log-in details.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        You can instruct your browser to refuse all cookies or to indicate when a
                        cookie is being sent. However, if you do not accept cookies, you may not
                        be able to use some portions of our Service.
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>
                            <span style={{ textDecoration: "none" }}>&nbsp;</span>
                            </u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>Log Files</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        As is true of most websites, we gather certain information automatically
                        to analyze trends in the aggregate and administer the Offerings. This
                        information may include your Internet Protocol (IP) address (or the proxy
                        server you use to access the World Wide Web), your browser type, the pages
                        and files you viewed, your operating system, your actions in connection
                        with our Offerings, and date/time stamps associated with your usage.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Web Beacons</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        We use web beacons alone or in conjunction with cookies to compile
                        information about your usage of the Offerings and interaction with emails
                        from us. Web beacons are clear electronic images that can recognize
                        certain types of information on your computer, such as cookies, when you
                        viewed a particular website or Service tied to the web beacon, and a
                        description of a website or Service tied to the web beacon. For example,
                        we may place web beacons in marketing emails that notify us when you click
                        on a link in the email that directs you to one of our websites. We also
                        use web beacons to operate and improve the Offerings and our email
                        communications.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                        <b>
                            <i>
                            <span style={{ fontSize: "24.0pt", lineHeight: "115%" }}>
                                Use of Information
                            </span>
                            </i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        We use the information we collect in the following ways:
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Personal Contact Information</u>
                        </b>
                        :
                        </p>
                        <p className="MsoNormal">
                        We use this information to manage your account, to provide the Services,
                        to maintain our customer/visitor lists, to respond to your inquiries or
                        request feedback, for identification and authentication purposes, for
                        service improvement, and to address issues like malicious use of the
                        Services. We may also use Personal Contact Information for limited
                        marketing purposes, namely, to contact you to further discuss your
                        interest in the Services, and to send you information about us. You may
                        opt out of receiving any, or all, of these communications from us by
                        following the unsubscribe link or instructions provided in any email we
                        send.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Billing Information:</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        We use Billing Information to manage your account, to provide the
                        Services, and to check the financial qualifications of prospective
                        customers and to collect payment for the Services.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Usage and Analytics Information:</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        This information is used to analyze overall trends, to help us improve the
                        Services, to track and aggregate non-personal information, and to provide
                        the Services. For example, we use IP addresses to monitor the regions from
                        which customers and visitors navigate the Websites.
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>
                            <span style={{ textDecoration: "none" }}>&nbsp;</span>
                            </u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>Disclosure of Your Information</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        We will never sell your data to third parties. However, in the course of
                        business, we may hire third-party individuals and organizations to help us
                        improve the Offerings. For example, we may share your personal information
                        with third parties including our web host provider, other SaaS providers,
                        such as an email hosting service, payment processors or outside
                        contractors we hire to perform maintenance or assist us in securing our
                        website. For a list of such third parties, with links to their privacy
                        policies, see our "Service Providers" section below.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        We may also share your personal information with a third party if we have
                        your consent to do so.
                        </p>
                        <p className="MsoNormal">
                        In addition to the above, we may also share your personal information when
                        we have a good faith belief that access, use, preservation or disclosure
                        of such information is reasonably necessary to (a) satisfy any applicable
                        law, regulation, legal process or enforceable governmental request, (b)
                        enforce a Customer Agreement, including investigation of potential
                        violations thereof, (c) detect, prevent, or otherwise address fraud or
                        security or technical issues, or (d) protect against imminent harm to our
                        rights, property or safety, or that of our users or the public as required
                        or permitted by law. Furthermore, your Personal Information may be
                        transferred if we are involved in a merger, acquisition or asset sale.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        We will take all steps reasonably necessary to ensure that your data is
                        treated securely and in accordance with this Privacy Policy and no
                        transfer of your Personal Information will take place to an organization
                        or a country unless there are adequate controls in place.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        This Privacy Policy applies solely to information collected by us. Even if
                        a third party is affiliated with us through a business partnership or
                        otherwise, we are not responsible for the privacy practices of such third
                        party. We encourage you to familiarize yourself with the privacy policies
                        of such third parties to determine how they handle any information they
                        separately collect from you.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Service Providers</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        We may employ third party companies and individuals to facilitate our
                        Offerings ("Service Providers"), to provide the Offerings on our behalf,
                        to perform Offering-related services or to assist us in analyzing how our
                        Offerings are used. These third parties have access to your Personal
                        Information only to perform these tasks on our behalf and are obligated
                        not to disclose or use it for any other purpose.
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                        <b>
                            <i>Stripe</i>
                        </b>
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                        Used to process transactions related to the provision of the Offerings
                        </p>
                        <p className="MsoNormal" style={{ marginLeft: ".5in" }}>
                        <a href="https://stripe.com/privacy">https://stripe.com/privacy</a>
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                            <i>
                            <span style={{ fontSize: "24.0pt", lineHeight: "115%" }}>
                                Your Controls and Choices
                            </span>
                            </i>
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>Communication Preferences</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        If you no longer wish to receive our newsletter and promotional
                        communications, you may opt-out of receiving them by following the
                        instructions included on such communications or on the Offerings. Please
                        note, however, that you may be unable to opt-out of certain
                        service-related communications.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Blocking Cookies</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        You can remove or block certain cookies using the settings in your browser
                        but the Offerings may cease to function properly if you do so.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Choosing not to share your Personal Information</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        Where we need to collect your personal information by law, or to be able
                        to provide the Services to you and you do not provide that information
                        when requested (or you later ask to delete it), we may not be able to
                        provide you with the Services and may need to close your account. We will
                        tell you what information you must provide to receive the Services by
                        designating it as required in the Service or through other appropriate
                        means.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Right to Access, Edit, and Remove Your Information</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        You have a right to access personal information we hold about you.
                        Whenever you use our Websites or Services, we strive to make sure that the
                        Personal Information is correct. If that information is wrong, we give you
                        ways to update it quickly or to delete it - unless we have to keep that
                        information for legitimate business or legal purposes. When updating your
                        Personal Information, we may ask you to verify your identity before making
                        any changes. We may reject requests that are unreasonably repetitive,
                        require disproportionate technical effort (for example, developing a new
                        system or fundamentally changing an existing practice), risk the privacy
                        of others, or would be extremely impractical (for instance, requests
                        concerning information residing on backup systems). Where we can provide
                        information access and correction, we will do so for free, except where it
                        would require a disproportionate effort. Because we protect information
                        from accidental or malicious destruction, even after information is
                        deleted from the Services, it may not be immediately deleted from Sonic
                        Transcript's servers.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        To request removal of your Personal Information from our Websites,
                        Services or social media profiles, contact us at 
                        <strong>support@sonictranscript.com</strong> In some cases, we may not be able to
                        remove your Personal Information, in which case we will let you know if we
                        are unable to do so and why.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Security Measures</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        The security of your Personal Information is important to us. We maintain
                        a variety of appropriate technical and organizational safeguards to
                        protect your Personal Information. No method of transmission over the
                        Internet, method of electronic storage or other security methods are one
                        hundred percent secure, however. Therefore, while we strive to use
                        reasonable efforts to protect your Personal Information, we cannot
                        guarantee its absolute security.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        You are also responsible for helping to protect the security of your
                        Personal Information. For instance, never give out your password, and
                        safeguard your user name, password and personal credentials when you are
                        using the Services, so that other people will not have access to your
                        Personal Information. Furthermore, you are responsible for maintaining the
                        security of any personal computing device on which you utilize the
                        Services.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Changes to the Privacy Policy</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        We reserve the right to change this Privacy Policy at any time. If we
                        decide to change this Privacy Policy in the future, we will post or
                        provide appropriate notice. Except as explained below, any non-material
                        change (such as clarifications) to this Privacy Policy will become
                        effective on the date the change is posted, and any material changes will
                        become effective 30 days from their posting on{" "}
                            www.sonictranscript.com/privacy
                        or via email to your listed email address. Unless stated otherwise, our
                        current Privacy Policy applies to all Personal Information that we have
                        about you and your account. The date on which the latest update was made
                        is indicated at the top of this document. We recommend that you print a
                        copy of this Privacy Policy for your reference and revisit this policy
                        from time to time to ensure you are aware of any changes. Your continued
                        use of the Services signifies your acceptance of any changes.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        If applicable law requires your opt in consent to any particular amendment
                        to this Privacy Policy, the amendment will not apply to your Personal
                        Information until we receive such consent from you. To the extent we
                        cannot provide some or all of the Services without your consent to such
                        amendment to the Privacy Policy, your decision not to consent may result
                        in our having to limit your ability to use certain aspects of the
                        Services.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>No Children Under Age 13</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        The Offerings are not intended for use by anyone under the age of 13, nor
                        does Sonic Transcript knowingly collect or solicit personal information
                        from anyone under the age of 13. If you are under 13, you may not attempt
                        to register for the Offerings or send any information about yourself to
                        us, including your name, address, telephone number, or email address. In
                        the event that we confirm that we have collected personal information from
                        someone under the age of 13 without verification of parental consent, we
                        will delete that information promptly. If you are a parent or legal
                        guardian of a child under 13 and believe that we might have any
                        information from or about such child, please contact us at the email or
                        mailing address provided at the end of this Privacy Policy.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>International Transfer</u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        Sonic Transcription is headquartered in the United States and does not
                        have service providers in other countries. Therefore, your personal
                        information will not be transferred, stored, or processedoutside of the
                        United States .
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal" align="center" style={{ textAlign: "center" }}>
                        <b>
                            <span style={{ fontSize: "16.0pt", lineHeight: "115%" }}>
                            California Privacy Rights and Disclosures
                            </span>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        Pursuant to the California Consumer Privacy Act of 2018 ("CCPA"), natural
                        persons who are California residents have certain rights concerning their
                        personal information held by us, as described below.
                        </p>
                        <p className="MsoNormal">
                        Personal Information Collection, Use, and Disclosure: The following table
                        describes (i) the categories of personal information (as defined in the
                        CCPA) we may collect about you; (ii) the sources from which that
                        information is collected; and (iii) the business and/or commercial
                        purposes for collecting that information.
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <u>
                            <span style={{ textDecoration: "none" }}>&nbsp;</span>
                            </u>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <b>
                            <i>Identifiers</i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <i>Category</i>: Name, address, email, phone number, user ID, credit card
                        information, IP address, unique device identification numbers
                        </p>
                        <p className="MsoNormal">
                        <i>Source</i>: You
                        </p>
                        <p className="MsoNormal">
                        <i>Purpose</i>: Performing the Services, maintaining the quality or safety
                        of the Services, internal research, debugging, auditing and analysis of
                        user interactions, advancing our reasonable commercial or economic
                        interests
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <i>Commercial information</i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <i>Category</i>: Sales engagement history, purchase and consumption
                        history
                        </p>
                        <p className="MsoNormal">
                        <i>Source</i>: You
                        </p>
                        <p className="MsoNormal">
                        <i>Purpose</i>: Performing the Services, maintaining the quality or safety
                        of the Services, internal research, debugging, auditing and analysis of
                        user interactions, advancing our reasonable commercial or economic
                        interests
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <i>Internet or other electronic network activity information</i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <i>Category</i>: Browser type and settings, the date, time, and length of
                        your visit, your browsing history, system configuration information
                        </p>
                        <p className="MsoNormal">
                        <i>Source</i>: You
                        </p>
                        <p className="MsoNormal">
                        <i>Purpose</i>: Performing the Services, maintaining the quality or safety
                        of the Services, internal research, debugging, auditing and analysis of
                        user interactions, advancing our reasonable commercial or economic
                        interests
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <i>
                            Audio, electronic, visual, thermal, olfactory, or similar information
                            </i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <i>Category</i>: Audio files, electronic communications, your Data
                        </p>
                        <p className="MsoNormal">
                        <i>Source</i>: You
                        </p>
                        <p className="MsoNormal">
                        <i>Purpose</i>: Performing the Services, maintaining the quality or safety
                        of the Services, short-term, transient use; With your permission, we may
                        also use this information for internal research
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <i>Biometric information</i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <i>Category</i>: Voice recordings contained in your Data
                        </p>
                        <p className="MsoNormal">
                        <i>Source</i>: You
                        </p>
                        <p className="MsoNormal">
                        <i>Purpose</i>: Performing the Services, maintaining the quality or safety
                        of the Services, short-term, transient use; With your permission, we may
                        also use this information for internal research
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <i>Professional or employment-related information</i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        <i>Category</i>: Employer name, position, organization information
                        </p>
                        <p className="MsoNormal">
                        <i>Source</i>: You
                        </p>
                        <p className="MsoNormal">
                        <i>Purpose</i>: Performing the Services, maintaining the quality or safety
                        of the Service
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        We may disclose this information pursuant to the section above entitled
                        "Disclosure of Your Information." For the purposes of the CCPA, we do not
                        and will not sell your personal information.
                        </p>
                        <p className="MsoNormal">
                        California Privacy Rights: To the extent provided for by law and subject
                        to applicable exceptions, California residents have the following privacy
                        rights in relation to the personal information we collect:
                        </p>
                        <ul>
                            <li><p
                            className="MsoListParagraphCxSpFirst"
                            style={{ marginLeft: "0pt", textIndent: "0pt" }}
                            >
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>The
                            right to know what personal information we have collected and how we have
                            used and disclosed that personal information;
                            </p></li>
                            <li><p
                            className="MsoListParagraphCxSpMiddle"
                            style={{ marginLeft: "0pt", textIndent: "0pt" }}
                            >
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>The
                            right to request deletion of your personal information; and
                            </p></li>
                            <li><p
                            className="MsoListParagraphCxSpLast"
                            style={{ marginLeft: "0pt", textIndent: "0pt" }}
                            >
                            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp; </span>The
                            right to be free from discrimination relating to the exercise of the
                            foregoing privacy rights.
                            </p>
                            </li>
                        </ul>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Exercising Your Rights</u>
                        </b>
                        : California residents can exercise the above privacy rights by emailing
                        us at <b>support@soictranscript.com</b>
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Verification</u>
                        </b>
                        : in order to protect your personal information from unauthorized access
                        or deletion, we may require you to verify your login credentials before
                        you can submit a request to know or delete personal information. If you do
                        not have an account with us, or if we suspect fraudulent or malicious
                        activity, we may ask you to provide additional personal information for
                        verification. If we cannot verify your identity, we will not provide or
                        delete your personal information.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <u>Authorized Agents</u>
                        </b>
                        : you may submit a request to know or a request to delete your personal
                        information through an authorized agent. If you do so, the agent must
                        present signed written permission to act on your behalf and you may also
                        be required to independently verify your identity with us.
                        </p>
                        <p className="MsoNormal">&nbsp;</p>
                        <p className="MsoNormal">
                        <b>
                            <i>What If I Have Questions or Concerns?</i>
                        </b>
                        </p>
                        <p className="MsoNormal">
                        If you have questions or need to contact us about this Privacy Policy,
                        please email us at <b>support@soictranscript.com</b>.
                        </p>
                    </div>
                    </div>

            </div>
            <Footer />
        </div>
        
    );
    
};

export default PrivacyPolicy