import React from 'react'
import { useTranslation } from 'react-i18next';
import "./faq.css"

function HowReferralCodesWork() {
    const { t } = useTranslation("global");
    const howReferralCodeWorks = 'FAQ.howReferralCodeWorks.description'
  
    return (
      <div>
          <p className='faqText'>
           {t(howReferralCodeWorks)}
          </p>
      </div>
    )
}

export default HowReferralCodesWork