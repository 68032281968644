// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserSessionPersistence, signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import {doc, query, collection, where, getDocs, deleteDoc, setDoc, getDoc, getFirestore} from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import i18next from "i18next";
import { toast } from 'react-toastify';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfzd7-6iafbq7E8DSvdbiGmilYVu-fdMU",
  authDomain: "sonic-transcript.firebaseapp.com",
  projectId: "sonic-transcript",
  storageBucket: "sonic-transcript.appspot.com",
  messagingSenderId: "974427447093",
  appId: "1:974427447093:web:61fa0daab493092e24767c",
  measurementId: "G-FQXKYSFSR2"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);
export const db = getFirestore(app);
export const storage = getStorage(app);

const addToMailChimp = async (email) => {
  // const authenticationString = `Basic ${btoa(`apikey:${mailchimpKey}`)}`;
  // console.log(authenticationString)
  try {
    const response = await fetch(`https://us-central1-sonic-transcript-410518.cloudfunctions.net/mailChimp`, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `email=${encodeURIComponent(email)}`,
    });

    const responseData = await response;
    // console.log(responseData);
  
  } catch (error) {
    // console.error(error);
  }
}
export async function googleSignUp(navigate, addNewsLetter, setDisabled) {
    setDisabled(true);
    const toastId = toast.loading(i18next.t('global:firebase.signingUpLoading'))
    const handleUserCredential = async (userCred) => {

      const user = userCred.user;
      getDoc(doc(db, "users", user.uid)).then(async (document) => {
        if (document.exists()) {
          // alert('You have successfully signed in!')
          toast.update(toastId, {
            render: i18next.t('global:firebase.signInSuccessful'),
            type: 'success',
            isLoading: false,
            autoClose: 1000, // Automatically close after 1 second
          });
          
          navigate('/profile')
          return;
        } else {
          // Step 1: Check if the user already exists
          const userQuery = query(collection(db, "users"), where("email", "==", user.email));
          const querySnapshot = await getDocs(userQuery);
          let oldUserData = null;
          let oldUserId = null;
      
          if (!querySnapshot.empty) {
            // Step 2: Retrieve old user data
            querySnapshot.forEach((doc) => {
              oldUserData = doc.data();
              oldUserId = doc.id;
            });

            // console.log(oldUserData, 'oldUserData')
      
            // Step 3: Delete old user data
            await deleteDoc(doc(db, "users", oldUserId));
          }
          const newUserData = {
            name: user.displayName,
            email: user.email,
            profile_picture: user.photoURL,
            minutes: oldUserData ? oldUserData.minutes : 20, // Use old minutes if available
            ...oldUserData, // Merge old data if available
          };
          await setDoc(doc(db, "users", user.uid), newUserData);

          //create referral coupon
          await fetch(`https://us-central1-sonic-transcript-410518.cloudfunctions.net/createCoupon`, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `uid=${encodeURIComponent(user.uid)}`,
          });
      
          // const responseData = await response;

          if(addNewsLetter) {
            addToMailChimp(user.email)
          }
          if(window.fbq) {
              window.fbq('trackCustom', 'GoogleSignUp');
          }

          if(window.gtag) {
              window.gtag('event', 'googleSignUp', {'send_to': 'G-FQXKYSFSR2'})
          }
          setDisabled(false);
          toast.update(toastId, {
            render: i18next.t('global:firebase.signUpSuccessful'),
            type: 'success',
            isLoading: false,
            autoClose: 1000, // Automatically close after 1 second
          });
          navigate('/profile')
          // alert('You have successfully signed up!')
          return;
        }
      }).catch(async (error) => {
        setDisabled(false);
        toast.update(toastId, {
          render: i18next.t('global:firebase.errorSigningUpGeneral'),
          type: 'error',
          isLoading: false,
          autoClose: 3000, // Automatically close after 3 seconds
        });
        // alert('There was an error signing up. Please try again.')
      });
    }

    try {
      const userCred = await signInWithPopup(auth, new GoogleAuthProvider());
      handleUserCredential(userCred)
    } catch (error) {
      setDisabled(false);
      // signInWithRedirect(auth, provider)
          
      // .then(handleUserCredential).catch((error) => {
      //     // console.log("Error!",error);
      //     alert('There was an error signing up. Please try again.')
      //     // Handle Errors here.
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     // The email of the user's account used.
      //     const email = error.customData.email;
      //     // The AuthCredential type that was used.
      //     const credential = GoogleAuthProvider.credentialFromError(error);
      //     // ...
      // });
      toast.error(i18next.t('global:firebase.errorSigningUpGeneral'))
      // alert('There was an error signing up. Please try again.')
    }
}

export async function signUpFacebook(navigate, addNewsLetter, setDisabled) {
    setDisabled(true);
    const toastId = toast.loading(i18next.t('global:firebase.signingUpLoading'))
    const provider = new FacebookAuthProvider();
    const handleUserCredential = async (userCred) => {
      if(userCred === null) return;
      const user = userCred.user;
      getDoc(doc(db, "users", user.uid)).then(async (document) => {
        if (document.exists()) {
          toast.update(toastId, {
            render: i18next.t('global:firebase.signInSuccessful'),
            type: 'success',
            isLoading: false,
            autoClose: 1000, // Automatically close after 1 second
          });
          navigate('/profile')
          return;
        } else {
          // Step 1: Check if the user already exists
          const userQuery = query(collection(db, "users"), where("email", "==", user.email));
          const querySnapshot = await getDocs(userQuery);
          let oldUserData = null;
          let oldUserId = null;
      
          if (!querySnapshot.empty) {
            // Step 2: Retrieve old user data
            querySnapshot.forEach((doc) => {
              oldUserData = doc.data();
              oldUserId = doc.id;
            });

            // console.log(oldUserData, 'oldUserData')
      
            // Step 3: Delete old user data
            await deleteDoc(doc(db, "users", oldUserId));
          }

          const newUserData = {
            name: user.displayName,
            email: user.email,
            profile_picture: user.photoURL,
            minutes: oldUserData ? oldUserData.minutes : 20, // Use old minutes if available
            ...oldUserData, // Merge old data if available
          };

          await setDoc(doc(db, "users", user.uid), newUserData);

          //create referral coupon
          await fetch(`https://us-central1-sonic-transcript-410518.cloudfunctions.net/createCoupon`, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `uid=${encodeURIComponent(user.uid)}`,
          });

          if(addNewsLetter) {
            addToMailChimp(user.email)
          }
          if(window.fbq) {
              window.fbq('trackCustom', 'FacebookSignUp');
          }

          if(window.gtag) {
              window.gtag('event', 'facebookSignUp', {'send_to': 'G-FQXKYSFSR2'})
          }
          setDisabled(false);
          toast.update(toastId, {
            render: i18next.t('global:firebase.signUpSuccessful'),
            type: 'success',
            isLoading: false,
            autoClose: 1000, // Automatically close after 1 second
          });
          navigate('/profile')
          // alert('You have successfully signed up!')
          return;
        }
      }).catch(async (error) => {
        setDisabled(false);
        toast.update(toastId, {
          render: i18next.t('global:firebase.errorSigningUpGeneral'),
          type: 'error',
          isLoading: false,
          autoClose: 3000, // Automatically close after 3 seconds
        });
      });
    }
    try {
      const userCred = await signInWithPopup(auth, provider);
      handleUserCredential(userCred)
    } catch (error) {
      setDisabled(false);
      switch(error.code) {
        case 'auth/account-exists-with-different-credential':
          // alert('You have already created an account with this email via a different provider. Please sign in with the same email on google or email.')
          toast.update(toastId, {
            render: i18next.t('global:firebase.errorDifferentProvider'),
            type: 'error',
            isLoading: false,
            autoClose: 3000, // Automatically close after 3 seconds
          });
          break;
        default:
          // alert('There was an error signing up. Please try again.')
          toast.update(toastId, {
            render: i18next.t('global:firebase.errorSigningUpGeneral'),
            type: 'error',
            isLoading: false,
            autoClose: 3000, // Automatically close after 3 seconds
          });
          break;
      }
    }
}

// export function signUpEmail(email, password, navigate, addNewsLetter) {
//     try {
//       return createUserWithEmailAndPassword(auth, email, password)
//       .then(async (userCredentials) => {
//         await sendEmailVerification(auth.currentUser)
//         const user = userCredentials.user;
        
//         if(window.fbq) {
//           window.fbq('trackCustom', 'EmailSignUp');
//         }
//         if(window.gtag) window.gtag('event', 'emailSignUp', {'send_to': 'G-FQXKYSFSR2'})

//         await setDoc(doc(db, "users", user.uid), {
//             name: user.displayName,
//             email: user.email,
//             profile_picture : user.photoURL,
//             minutes: 20,
//         })
//         if(addNewsLetter) {
//           addToMailChimp(user.email)
//         }
//         navigate('/profile')
//       }).catch(error => {   
//         switch(error.code) {
//           case 'auth/email-already-in-use':
//                 alert('You have already created an account with this email.')
//                 break;
//           case 'auth/invalid-email':
//                 alert('Your email is incorrect.')
//                 break;
//         default:
//             alert('There was an error signing up. Please try again.')
//             break;
//         }
//       })
//     }catch(err){
//         alert("Error : ", err);
//     };
//   }

  export async function signUpEmail(email, password, navigate, addNewsLetter, setDisabled) {
    setDisabled(true);
    const toastId = toast.loading(i18next.t('global:firebase.signingUpLoading'))
    try {
      return createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredentials) => {
          await sendEmailVerification(auth.currentUser);
          const user = userCredentials.user;

          // Step 1: Check if the user already exists
          const userQuery = query(collection(db, "users"), where("email", "==", email));
          const querySnapshot = await getDocs(userQuery);
          let oldUserData = null;
          let oldUserId = null;
      
          if (!querySnapshot.empty) {
            // Step 2: Retrieve old user data
            querySnapshot.forEach((doc) => {
              oldUserData = doc.data();
              oldUserId = doc.id;
            });

            // console.log(oldUserData, 'oldUserData')
      
            // Step 3: Delete old user data
            await deleteDoc(doc(db, "users", oldUserId));
          }
  
          if (window.fbq) {
            window.fbq('trackCustom', 'EmailSignUp');
          }
          if (window.gtag) window.gtag('event', 'emailSignUp', { 'send_to': 'G-FQXKYSFSR2' });
  
          // Step 5: Use old data to populate the new account
          const newUserData = {
            name: user.displayName,
            email: user.email,
            profile_picture: user.photoURL,
            minutes: oldUserData ? oldUserData.minutes : 20, // Use old minutes if available
            ...oldUserData, // Merge old data if available
          };
  
          await setDoc(doc(db, "users", user.uid), newUserData);

          //create referral coupon
          await fetch(`https://us-central1-sonic-transcript-410518.cloudfunctions.net/createCoupon`, {
            method: 'POST',
            mode: 'no-cors',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `uid=${encodeURIComponent(user.uid)}`,
          });
  
          if (addNewsLetter) {
            addToMailChimp(user.email);
          }
          
          navigate('/profile');
          toast.update(toastId, {
            render: i18next.t('global:firebase.signingUpLoading'),
            type: 'success',
            isLoading: false,
            autoClose: 1000, // Automatically close after 1 second
          });
          setDisabled(false);
        }).catch(error => {
          setDisabled(false);
          switch (error.code) {
            case 'auth/email-already-in-use':
              // alert('You have already created an account with this email.');
              toast.update(toastId, {
                render: i18next.t('global:firebase.errorAccountCreated'),
                type: 'error',
                isLoading: false,
                autoClose: 3000, // Automatically close after 1 second
              });
              break;
            case 'auth/invalid-email':
              // alert('Your email is incorrect.');
              toast.update(toastId, {
                render: i18next.t('global:firebase.errorInvalidEmail'),
                type: 'error',
                isLoading: false,
                autoClose: 3000, // Automatically close after 1 second
              });
              break;
            default:
              // alert('There was an error signing up. Please try again.');
              toast.update(toastId, {
                render: i18next.t('global:firebase.errorSigningUpGeneral'),
                type: 'error',
                isLoading: false,
                autoClose: 3000, // Automatically close after 1 second
              });
              break;
          }
        });
    } catch (err) {
      setDisabled(false);
      toast.update(toastId, {
        render: i18next.t('global:firebase.errorSigningUpGeneral'),
        type: 'error',
        isLoading: false,
        autoClose: 3000, // Automatically close after 3 seconds
      });
      // alert("Error: ", err);
      // console.log(err);
    }
  }

export async function verify(auth){
    return await sendEmailVerification(auth.currentUser)
}
export function signInEmail(email, password, navigate) {
    const toastId = toast.loading(i18next.t('global:firebase.signingInLoading'))
    try {
      return signInWithEmailAndPassword(auth, email, password).then(() => {
        // alert('You have successfully signed in!')
        toast.update(toastId, {
          render: i18next.t('global:firebase.signInSuccessful'),
          type: 'success',
          isLoading: false,
          autoClose: 1000, // Automatically close after 1 second
        });
        navigate('/profile')
      }).catch(error => {   
        switch(error.code) {
          case 'auth/email-already-in-use':
                toast.update(toastId, {
                  render: i18next.t('global:firebase.errorAlreadyHaveAccount'),
                  type: 'error',
                  isLoading: false,
                  autoClose: 3000, // Automatically close after 3 seconds
                });
                break;
          case 'auth/invalid-email':
                toast.update(toastId, {
                  render: i18next.t('global:firebase.errorInvalidEmail'),
                  type: 'error',
                  isLoading: false,
                  autoClose: 3000, // Automatically close after 3 seconds
                });
                break;
          default:
              toast.update(toastId, {
                render: i18next.t('global:firebase.errorSigningInGeneral'),
                type: 'error',
                isLoading: false,
                autoClose: 3000, // Automatically close after 3 seconds
              });
             break;
        }
      })
    }catch(err){
      toast.update(toastId, {
        render: i18next.t('global:firebase.errorSigningInGeneral'),
        type: 'error',
        isLoading: false,
        autoClose: 3000, // Automatically close after 3 seconds
      });
    };
  }

  export async function resetPassword(email) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(i18next.t('global:firebase.passwordResetSent'))
      // alert("Password reset email sent!");
    } catch (err) {
      console.log("Error", err);

      switch (err.code) {
        case 'auth/missing-email':
          toast.error(i18next.t('global:firebase.passwordResetSent'))
          // alert("Please enter an email address.");
          break;
        case "auth/user-not-found":
          // alert("You haven't created an account with this email. Please sign up!");
          toast.error(i18next.t('global:firebase.enterEmail'));
          break;
        case "auth/invalid-email":
          // alert("Invalid email");
          toast.error(i18next.t('global:firebase.errorNoUserFound'));
          break;
        default:
          // alert("There was an error resetting your password. Please try again.");
          toast.error(i18next.t('global:firebase.errorPasswordReset'));
          break;
      }
    }
  }