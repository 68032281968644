import React, { useState } from 'react';
import { AiFillGoogleCircle, AiFillFacebook } from "react-icons/ai";
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
// import { useLocation } from 'react-router-dom'
import { googleSignUp, signInEmail, signUpEmail, signUpFacebook, resetPassword} from '../../firebase';
import './signup.css';

const SignUp = () => {

    const { t } = useTranslation("global");

    let {state} = useLocation();
    const gate = state == null ? true : state.signUp;
    const emailLogic = state == null ? '' : state.email;
    const getStartedLogic = state == null ? undefined : state.getStarted;
    const [getStarted, setGetStarted] = useState(getStartedLogic);
    const navigate = useNavigate();
    const iconSize = 20;
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
    const [signUp, setSignUp] = useState(gate);
    const [signUpText, setSignUpText] = useState(t('signUp.signUpText1'));
    const [email, setEmail] = useState(emailLogic);
    const [googleText, setGoogleText] = useState(signUp ? t('signUp.googleText1') : t('signUp.googleText2'));
    const [checkNewsLetter, setCheckNewsLetter] = useState(getStartedLogic !== undefined ? false : true);
    const [disabled, setDisabled] = useState(false);

    const handlePasswordChange = (e) => {
        const passwordValue = e.target.value;
        const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(passwordValue);
        const hasNumber = /\d/.test(passwordValue);
        const isLongEnough = passwordValue.length >= 8;

        setPassword(passwordValue);
        setPasswordMatch(passwordValue === confirmPassword);

        const requirements = [
            t('signUp.passRequire1'),
            t('signUp.passRequire2'),
            t('signUp.passRequire3')
        ];

        const updatedRequirements = requirements.filter(requirement => {
            if (hasSpecialCharacter && requirement.includes(t("signUp.passCheck1"))) {
                return false;
            }
            if (hasNumber && requirement.includes(t("signUp.passCheck2"))) {
                return false;
            }
            if (isLongEnough && requirement.includes(t("signUp.passCheck3"))) {
                return false;
            }
            return true;
        });

        setPasswordErrorMessage(updatedRequirements.join("\n\n"));
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        
    }
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordMatch(e.target.value === password && password.length > 0 && e.target.value.length > 0);
    };

    const handleSignUpChange = () => {
        setSignUp(!signUp);
        setSignUpText(signUp ? t("signUp.signUpText1") : t("signUp.signUpText2"));
        setGoogleText(signUp ? t("signUp.googleText2") : t("signUp.googleText1"));
    }


    return (
        <div className="signup-container">
            <Helmet>
                <title>Sonic Transcript Sign Up</title>
                <meta name='description' content='Sonic Transcript. Get you Transcript in Minutes! Sign up Today and get a Free 20 minutes to try our services!' />
                <meta name="keywords" content="transcription services, researchers, interviews, lectures, fast transcription, accurate transcription, google scholar" />
                <link rel="canonical" href="https://www.sonictranscript.com" />
                <link rel="icon" href="https://www.sonictranscript.com/favicon.ico" /> {/* Add this line */}
            </Helmet>
            {/* <h2>{signUp ? "Sign Up" : "Sign In"}</h2>
                <p><strong>Warning: </strong> On June 18, 2024, our google sign in will not work if you update the following browsers to the following versions:</p>
            <br />
                <ul style={{marginLeft: 40}}>
                    <li>Safari 16.1+ on macOS</li>
                    <li>iOS 16.1+</li>
                    <li>Firefox 109+</li>
                    <li>Chrome M115+</li>
                </ul>
            <br />
            <p>If you need to sign in, please use an older version of the browser or a different browser.</p> */}
            <br />
            {getStartedLogic !== undefined ?
                <>
                    <h3>{getStarted}</h3> 
                    {/* <br /> */}
                </>
                : 
                <>
                    <h3>{t("signUp.title")}</h3> 
                    {/* <br /> */}
                </>
            }
            
            <div>
                <div className="form-group">
                    <label>{t("signUp.email")}</label>
                    <input type="email" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
                </div>

                <div className="form-group">
                    <label>{t("signUp.password")}</label>
                    <input type="password" placeholder="Enter your password" value={password} onChange={handlePasswordChange} style={{ border: passwordMatch ? '2px solid green' : '2px solid red' }} />
                    {passwordErrorMessage && <pre className="password-error-message">{passwordErrorMessage}</pre>}
                </div>
                {!signUp && (
                
                    <>
                        <div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={checkNewsLetter}
                                onChange={(e) => setCheckNewsLetter(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <p>{t("signUp.newsLetter")}</p>
                        </div>
                        <div className="form-group">
                            <a href="/forgot-password" onClick={async (event) => {
                                event.preventDefault(); // Prevent the default action
                                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email
                                if (!emailRegex.test(email)) {
                                    alert(t("signUp.invalidEmail"));
                                    return;
                                }
                                if(window.fbq) {
                                    window.fbq('trackCustom', 'PassReset');
                                }

                                await resetPassword(email);
                            }}>
                                {t("signUp.forgotPass")}
                            </a>
                            {/* <button 
                                className="btn" 
                                onClick={async () => {
                                    if(window.fbq) {
                                        window.fbq('trackCustom', 'PassReset');
                                    }
                                    await resetPassword(email);
                                }}>
                            Reset Password
                            </button> */}
                        </div>
                    </>
                    
                )}
                {signUp ? (
                    <div>
                        <div className="form-group">
                            <label>{t("signUp.confirmPass")}</label>
                            <input type="password" placeholder="Confirm your password" value={confirmPassword} onChange={handleConfirmPasswordChange} style={{ border: passwordMatch ? '2px solid green' : '2px solid red' }} />
                            {/* {passwordMatch ? <span className="password-match-indicator">Passwords match</span> : <span className="password-match-indicator">Passwords do not match</span>} */}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={checkNewsLetter}
                                onChange={(e) => setCheckNewsLetter(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <p>{t("signUp.checkingBox")}</p>
                        </div>
                        <button 
                            className="btn"
                            disabled={disabled} 
                            onClick={() => {
                                if (passwordMatch) {
                                    signUpEmail(email, password, navigate, checkNewsLetter, setDisabled)
                                } else {
                                    alert(t("signUp.passMatchError"));
                                }
                            }}
                        >
                            {t("signUp.signUpButton")}
                        </button>
                    </div>
                ) : (
                    <div className="form-group">
                        <button 
                            className="btn"
                            disabled={disabled} 
                            onClick={() => {
                                signInEmail(email, password, navigate)
                            }}
                        >
                            <strong>{t("signUp.signInButton")}</strong>
                        </button>
                    </div>
                )}
                
            </div>
            <div className="social-login">
                <button 
                    className="btn google-btn" 
                    disabled={disabled}
                    onClick={() => {
                        googleSignUp(navigate, checkNewsLetter, setDisabled)
                    }}
                >
                    {t("signUp.signUpButton2")} {googleText} {t("signUp.signUpButton2Google")} <AiFillGoogleCircle style={{ marginLeft: '10px' }} size={iconSize} />
                </button>
                <button 
                    className="btn facebook-btn"
                    disabled={disabled} 
                    onClick={() => {
                        signUpFacebook(navigate, checkNewsLetter, setDisabled)
                    }}
                >
                    {t("signUp.signUpButton2")} {googleText} {t("signUp.signUpButton2Facebook")} <AiFillFacebook style={{ marginLeft: '10px' }} size={iconSize} />
                </button>
                <button className="btn switch-btn" onClick={handleSignUpChange}>{signUpText}</button>
            </div>
        </div>
    );
};


export default SignUp