
import React, { useEffect, useState } from 'react';
import {doc, updateDoc, getDoc, onSnapshot} from 'firebase/firestore';
import {getAuth, onAuthStateChanged, signOut} from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Navbar, LogList } from '../../components';
import {db, app, verify} from '../../firebase';
import "./profile.css";

const Profile = () => {

  const { t } = useTranslation("global");

  const [minutes, setMinutes] = useState(0);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [referralCode, setReferralCode] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [corp, setCorp] = useState(false);
  const [newName, setNewName] = useState('');
  const [authUser, setAuthUser] = useState("");
  const [uid, setUid] = useState("");
  const [clientId, setClientId] = useState("");
  const [buttonId, setButtonId] = useState(undefined);
  const [corporationName, setCorporationName] = useState("");
  const [anotherEmail, setAnotherEmail] = useState(t("profile.anotherEmail1"));
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    }).catch(err => {
      setCopySuccess('Failed to copy!');
    });
  };
  const navigate = useNavigate();

  useEffect(() => {
    // Get the user's information from Firestore
    // console.log(auth.currentUser.uid, 'auth')
    const auth = getAuth(app);
    // const sessionId = sessionStorage.getItem('sessionId');

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        
        setAuthUser(user);
        setUid(user.uid);
        try {
          // console.log(auth.currentUser, 'auth')
          // if(auth.currentUser.uid == null) return;
          // console.log(authUser, 'authUser')
          const userRef = doc(db, 'users', user.uid); // Replace with the actual user ID
          const unsubscribe = onSnapshot(userRef, (userData) => {
            if (userData.exists()) {

              const { minutes, name, email, corporation, referralCode } = userData.data();
              
              setName(name);
              setEmail(email);
              if (corporation) {
                // Assuming `db` is your Firestore instance and `corporationCollection` is the name of your collection
                setCorporationName(corporation.replace(/_/g, ' '));
                const corpRef = doc(db, "corporations", corporation);
                getDoc(corpRef).then((corpDoc) => {
                  if (corpDoc.exists()) {
                    const corpData = corpDoc.data();
                    setUid(corpDoc.id);
                    setButtonId(corpData.buttonId);
                    // Assuming the corporation document has a `minutes` field
                    const corporationMinutes = corpData.minutes;
                    // Now you can use `corporationMinutes` as needed
                    setMinutes(corporationMinutes);
                    // If you need to update the state or perform any action with the corporation minutes, do it here
                  } else {
                    // console.log("No such corporation document!");
                  }
                }).catch((error) => {
                  // console.error("Error fetching corporation data:", error);
                });
                setCorp(true);
              } else {
                setMinutes(minutes);
                setReferralCode(referralCode);
              }
              // setIsGoogleLogin(isGoogleLogin);
            }
          }, (error) => {
            // console.error('Error fetching user data:', error);
          });
          return () => unsubscribe();
        } catch (error) {
          // console.error('Error fetching user data:', error);
        }
        // ...
      } else {
        // User is signed out
        // console.log('user is signed out', user)
        navigate('/');
        // ...
      }
    });
    
  }, [navigate]);


  const handleNameEdit = () => {
    setIsEditingName(true);
    setNewName(name);
  };

  const handleNameUpdate = async () => {
    try {
      const auth = getAuth(app);
      const userId = auth.currentUser.uid; // Replace with the actual user ID
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { name: newName });
      setName(newName);
      setIsEditingName(false);
    } catch (error) {
      // console.error('Error updating name:', error);
    }
  };

  const signOutUser = () => {
    const auth = getAuth(app);
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }

  const verifyEmail = () => {
    const auth = getAuth(app);
    verify(auth).then(() => {
      setAnotherEmail(t("profile.anotherEmail2"));
    });
  }

  function getGAclientId() {
    var gaCookie = document.cookie.split('; ').find(row => row.startsWith('_ga'));
    if (gaCookie) {
      var ga = gaCookie.split('=')[1].split('.');
      var clientId = ga[2] + "_" + ga[3];
      return clientId;
    }
    return null;
  }
  
  useEffect(() => {
    const clientId = getGAclientId();
    if (clientId) {
      setClientId(clientId);
    }
  }, [clientId]);

  return (
    <div className='App'>
      <div className='bg__normal'>
        <Navbar />
      </div>
      <div className='side-by-side'>
        <div className="profile-container">
          <div className="profile-title">{corp ? t("profile.dashboard")+corporationName:t("profile.profile")}</div>
          <div className="minutes-title">{t("profile.numMinutes")}{minutes}</div>
          <div className="name-title">{t("profile.name")}{isEditingName ? <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} /> : name}</div>
          <div className="email-title">{t("profile.email")}{email}</div>
          <div className="email-title">
            {t("profile.referralCode")}{referralCode}
            <button onClick={copyToClipboard} className="email-verify-button" style={{ marginLeft: '10px' }}>
              <FontAwesomeIcon icon={faCopy} size="lg" />
            </button>
            {copySuccess && <span style={{ marginLeft: '10px', color: 'green' }}>{copySuccess}</span>}
            <Link to="/faq" className="faq-link">
              Learn more about referral codes
            </Link>
          </div>
          {/* <div className="login-method-title">Login Method: {isGoogleLogin ? 'Google' : 'Email'}</div> */}
          {isEditingName ? (
            <button className="save-button" onClick={handleNameUpdate}>{t("profile.save")}</button>
          ) : (
            <button className="edit-button" onClick={handleNameEdit}>{t("profile.editName")}</button>
          )}
          {/* {isGoogleLogin ? null : (
            <button className="password-button" onClick={handlePasswordChange}>Change Password</button>
          )} */}
          <button className="logout-button" style={{marginLeft: '0.625rem'}} onClick={signOutUser}>{t("profile.logout")}</button>
          <LogList />

        </div>

        {authUser.emailVerified ? (
          <div className="price-container">
            {/* <div className="price-title">Warning: It my take a minute for your minutes to update after a purchase.</div> */}
            {/* <div className="price-title">Use the Promo Code ST25 to get 25% off!!!</div> */}
            <div>
              {/* Corporate Button */}
              {corp && buttonId !== undefined ? (
                <>
                  <div className="price-title">{t("profile.welcome")} {corporationName}</div>
                  <div className="centered-button">
                    <stripe-buy-button
                      buy-button-id={buttonId}
                      publishable-key="pk_live_51OUWcuF0otBBHggVtEIAbzXjw1TsXDCJmVcLtujss1t2UrefQVWOegmkchaWvh1V729dZAGZsd5ldzlwsDFTj4DK00ISiJ8aqh"
                      client-reference-id={uid+" "+clientId+" true"}
                    >
                    </stripe-buy-button>
                    {/* <stripe-buy-button
                      buy-button-id="buy_btn_1PdcG9F0otBBHggVg1TJpLzt"
                      publishable-key="pk_test_51OUWcuF0otBBHggV6XkUzzbvoJqXy5YS3XfYpluP3p8GBBIjriZyxBPVtNnhDRF85HSGegOcz2dhv32rDsxzetiC00EFJsYPFZ"
                      client-reference-id={uid+" "+clientId+" true"}
                    >
                    </stripe-buy-button> */}
                  </div>
                </>
              ):(
                <>
                {/* <stripe-pricing-table
                  pricing-table-id="prctbl_1PdZ3yF0otBBHggViGMj00JQ"
                  publishable-key="pk_live_51OUWcuF0otBBHggVtEIAbzXjw1TsXDCJmVcLtujss1t2UrefQVWOegmkchaWvh1V729dZAGZsd5ldzlwsDFTj4DK00ISiJ8aqh"
                  client-reference-id={uid+" "+clientId}
                >
                </stripe-pricing-table> */}
                 {/* Test */}
                  {/* <stripe-pricing-table
                    pricing-table-id="prctbl_1OUeugF0otBBHggVSQCMjB0V"
                    publishable-key="pk_test_51OUWcuF0otBBHggV6XkUzzbvoJqXy5YS3XfYpluP3p8GBBIjriZyxBPVtNnhDRF85HSGegOcz2dhv32rDsxzetiC00EFJsYPFZ"
                    client-reference-id={uid+" "+clientId}
                  >
                  </stripe-pricing-table> */}
                  <stripe-pricing-table 
                    pricing-table-id="prctbl_1OXuH8F0otBBHggVwgOQJK5u"
                    publishable-key="pk_live_51OUWcuF0otBBHggVtEIAbzXjw1TsXDCJmVcLtujss1t2UrefQVWOegmkchaWvh1V729dZAGZsd5ldzlwsDFTj4DK00ISiJ8aqh"
                    client-reference-id={uid+" "+clientId}
                  >
                  </stripe-pricing-table>

                  {/* <stripe-pricing-table
                    pricing-table-id="prctbl_1OUf6dF0otBBHggV6Zz9JFRF"
                    publishable-key="pk_test_51OUWcuF0otBBHggV6XkUzzbvoJqXy5YS3XfYpluP3p8GBBIjriZyxBPVtNnhDRF85HSGegOcz2dhv32rDsxzetiC00EFJsYPFZ"
                    client-reference-id={authUser.uid}
                  >
                  </stripe-pricing-table> */}
                  <stripe-pricing-table 
                    pricing-table-id="prctbl_1OXuJWF0otBBHggVfy6deRGE"
                    publishable-key="pk_live_51OUWcuF0otBBHggVtEIAbzXjw1TsXDCJmVcLtujss1t2UrefQVWOegmkchaWvh1V729dZAGZsd5ldzlwsDFTj4DK00ISiJ8aqh"
                    client-reference-id={uid+" "+clientId}
                  >
                  </stripe-pricing-table>
                </>
              )}
              
              
              
            </div>
          </div>
        ) : (
          <div className="price-container">
            <div className="email-verify">{t("profile.emailVerifyMessage1")}</div>
            <div className="email-verify">{t("profile.emailVerifyMessage2")} {anotherEmail} <button className='email-verify-button' onClick={verifyEmail}>{t("profile.emailButton")}</button></div>
          </div>
        )}
        
      </div>
    </div>
    
  );
};

export default Profile;