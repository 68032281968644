import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Navbar } from '../../components';
import { AAF, Footer, Languages } from '../../containers';
import PurchaseMinutes from './PurchaseMinutes';
import EmailUs from './EmailUs';
import HowSonicTranscriptWorks from './HowSonicTranscriptWorks';
import HowMinutesWork from './HowMinutesWork';
import HowTranslationWorks from './HowTranslationWorks';
import HowReferralCodesWork from './HowReferralCodesWork';
import './faq.css';

const A2 = () => {

    const { t } = useTranslation("global");
    const translate = 'FAQ.faq';

    return (
        <div>
            <br />
            <p className='faqText'>
                {t(translate + '.a2Text')}
            </p>
        </div>
    );
}

const A3 = () => {

    const { t } = useTranslation("global");
    const translate = 'FAQ.faq';

    return (
        <div>
            <p className='faqText'>{t(translate+".a3p1Text")}</p>
            {/* <br /> */}
            <p className='faqText'>{t(translate+".a3p2Text")}</p>
            {/* <br /> */}
            <p className='faqText'>{t(translate+".a3p3Text")}</p>
            <p className='faqText'><strong>{t(translate+".a3p4Text")}</strong></p>
            {/* <br /> */}
            <p className='faqText'>{t(translate+".a3p5Text")}</p>
        </div>
    );
}

const FAQ = () => {

    const { t } = useTranslation("global");
    const translate = 'FAQ.faq';

    const [openIndex, setOpenIndex] = useState(null);
    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const questions = [
        <h2>{t(translate+".question1")}</h2>,
        <h2>{t(translate+".question2")}</h2>,
        <h2>{t(translate+".question9")}</h2>,
        <h2>{t(translate+".question10")}</h2>,
        <h2>{t(translate+".question3")}</h2>,
        <h2>{t(translate+".question4")}</h2>,
        <h2>{t(translate+".question5")}</h2>,
        <h2>{t(translate+".question6")}</h2>,
        <h2>{t(translate+".question7")}</h2>,
        <h2>{t(translate+".question8")}</h2>
    ];
    const answers = [ 
        <A3 />, 
        <HowSonicTranscriptWorks />,
        <HowTranslationWorks />,
        <HowReferralCodesWork />,
        <HowMinutesWork />,
        <PurchaseMinutes />,
        <A2 />, 
        <AAF />,
        <Languages />,
        <EmailUs />
    ];

    return (
        <div className='App'>
            <Helmet>
                <title>Sonic Transcript Frequently Asked Questions</title>
                <meta name='description' 
                      content='At Sonic Transcript we understand the security needs of researchers using a transcription services. 
                      We have on staff a leading consultant in the field of research integrity. 
                      Though your IRB may not yet have dealt with the data security of an on-line transcript service, 
                      here is some information to share with them that should help them understand that our service is actually 
                      as secure or more so than using human transcription services.' 
                    />
                <meta name="keywords" content="transcription services, researchers, interviews, lectures, fast transcription, accurate transcription" />
                <link rel="canonical" href="https://www.sonictranscript.com" />
                <link rel="icon" href="https://www.sonictranscript.com/favicon.ico" /> {/* Add this line */}
            </Helmet>
            <div className='bg__normal'>
                <Navbar />
            </div>
            <div>
                <h1>{t(translate+".title")}</h1>
                <ul className='center faq-list'>
                    {questions.map((question, index) => (
                        <li key={index} className='faq-item'>
                            <div className='QnA' onClick={() => toggleDropdown(index)}>
                                {question}
                                <span className={`arrow ${openIndex === index ? 'rotate-center' : ''}`}>
                                    &#65088;
                                </span>
                            </div>
                            {openIndex === index && <div>{answers[index]}</div>}
                        </li>
                    ))}
                </ul>
            </div>
         <Footer />

        </div>
    );
};

export default FAQ;