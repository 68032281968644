import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Product, Navbar } from '../../components';
import { Footer } from '../../containers';
import "./pricing.css";

const Pricing = () => {

    const { t } = useTranslation("global");

    const products = [
        { id: 0, title: t("pricing.title_0"), prices: [t("pricing.price_0")], included: [t("pricing.included_0p1"), t("pricing.included_0p2")] },
        { id: 8, title: t("pricing.title_8"), prices: [t("pricing.price_8")], included: [t("pricing.included_8p1"), t("pricing.included_8p2")] },
        { id: 9, title: t("pricing.title_9"), prices: [t("pricing.price_9")], included: [t("pricing.included_9p1"), t("pricing.included_9p2")] },
        { id: 1, title: t("pricing.title_1"), prices: [t("pricing.price_1")], included: [t("pricing.included_1p1"), t("pricing.included_1p2")] },
        { id: 2, title: t("pricing.title_2"), prices: [t("pricing.price_2")], included: [t("pricing.included_2p1"), t("pricing.included_2p2"), t("pricing.included_2p3")] },
        { id: 3, title: t("pricing.title_3"), prices: [t("pricing.price_3")], included: [t("pricing.included_3p1"), t("pricing.included_3p2"), t("pricing.included_3p3")] },
        { id: 4, title: t("pricing.title_4"), prices: [t("pricing.price_4")], included: [t("pricing.included_4p1"), t("pricing.included_4p2"), t("pricing.included_4p3")] },
        { id: 5, title: t("pricing.title_5"), prices: [t("pricing.price_5")], included: [t("pricing.included_5p1"), t("pricing.included_5p2"), t("pricing.included_5p3")] },
        { id: 6, title: t("pricing.title_6"), prices: [t("pricing.price_6")], included: [t("pricing.included_6p1"), t("pricing.included_6p2"), t("pricing.included_6p3")]},
        { id: 7, title: t("pricing.title_7"), prices: [t("pricing.price_7")], included: [t("pricing.included_7p1"), t("pricing.included_7p2"), t("pricing.included_7p3")] }
        // Add more products here
    ];
    
    const junePromo = 
        <>
            <br /><br />
            {t("pricing.subTitle_2p3")}<strong>{t("pricing.subTitle_2p4")}</strong>{t("pricing.subTitle_2p5")}
        </>

    return (
        <div className="App">
            <Helmet>
                <title>Sonic Transcript Pricing for our Packs</title>
                <meta name='description' content='All packs are based on getting $0.20 per audio minute. The more minutes you buy, the more you save. Sign up today to get 20 Free Minutes.' />
                <meta name="keywords" content="transcription services, researchers, interviews, lectures, fast transcription, accurate transcription, google scholar" />
                <link rel="icon" href="https://www.sonictranscript.com/favicon.ico" /> {/* Add this line */}
                <link rel="canonical" href="https://www.sonictranscript.com" />
            </Helmet>
            <div className='bg__normal'>
                <Navbar />
            </div>
            <div className="pricing-title">{t("pricing.title")}</div>
                <div className="pricing-subtitle">{t("pricing.subTitle_1")}</div>
                {/* <div className="pricing-subtitle">
                {t("pricing.subTitle_2p1")}<strong>{t("pricing.subTitle_2p2")}</strong>
                    {junePromo}
                </div> */}
                <div className="pricing-subtitle">
                    {t("pricing.subTitle_3p1")} 
                    <br />
                    <strong>
                        <a href="mailto:support@sonictranscript.com?subject=Interested%20in%20Custom%20Solution&body=Hello,%20I'm%20interested%20in%20a%20custom%20solution.">
                            {t("pricing.subTitle_3p2")}
                        </a>    
                    </strong> 
                    <br />
                    {t("pricing.subTitle_3p3")}
                </div>
                <div className="product-grid">
                    {products.map((product) => (
                        <Product key={product.id} title={product.title} prices={product.prices} included={product.included} />
                    ))}
                </div>
            <Footer />
        </div>
    );
};

export default Pricing;