import React from 'react'
import { useTranslation } from 'react-i18next';
import translateOne from "../../assets/translation/translate_1.webp"
import translateTwo from "../../assets/translation/translate_2.webp"
import translateThree from "../../assets/translation/translate_3.webp"
import translateFour from "../../assets/translation/translate_4.webp"
import txt from "../../assets/translation/text.txt"
import pdf from "../../assets/translation/pdf.pdf"
import word from "../../assets/translation/word.docx"

const HowTranslationWorks = () => {

    const { t } = useTranslation("global");
    const string = 'FAQ.howTranslationWorks'

    const items = [
        { 
            image: translateOne,
            header: t(string + '.header1'),
            text: t(string + '.text1'),
        },
        { 
            image: translateTwo,
            header: t(string + '.header2'),
            text: t(string + '.text2'),
        },
        { 
            image: translateThree, 
            header: t(string + '.header3'),
            text: t(string + '.text3'),
        },
        { 
            image: translateFour, 
            header: t(string + '.header4'),
            text: t(string + '.text4'),
            downloads: [
                { type: 'text', link: txt, name: t(string + '.name1') },
                { type: 'pdf', link: pdf, name: t(string + '.name2')},
                { type: 'word', link: word, name: t(string + '.name3') }
            ]
        },
    ];
  return (
    <div>
      {items.map((item, index) => (
            <div key={index} style={{padding: '20px', justifyContent: 'center'}}>
                <h2 style={{textAlign: 'center', margin: '20px'}}>{item.header}</h2>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={item.image} alt={item.text} style={{width: '90%', height: 'auto'}} />
                </div>
                <p className='faqText'>{item.text}</p>
                {item.downloads && (
                    <div className="download-container">
                        {item.downloads.map((download, index) => (
                            <a key={index} href={download.link} download={download.name} className={`download-link ${download.type}`}>
                                <img src={require(`../../assets/icons/${download.type}.png`)} alt={`${download.type} icon`} style={{height: '20px', width: '20px'}} />
                                 {t(string + '.download')} {download.type}
                            </a>
                        ))}
                    </div>
                )}
            </div>
        ))}
    </div>
  )
}

export default HowTranslationWorks