import React from 'react'
import { Helmet } from 'react-helmet'
import { Navbar } from '../../components'
import {Footer, Header, WhatGPT3, TabBox} from '../../containers'
import './home.css'

const Home = () => {
  return (
    <div className='App'>
      <Helmet>
        <title>Sonic Transcript</title>
        {/* <meta name='description' content='Sonic Transcript. Get you Transcript in Minutes!' /> */}
        <meta name="description" content="Experience Sonic Transcript: Multi-speaker detection, $0.20/min, fast, and secure. Multilingual service for all your transcription needs." />
        <meta name="keywords" content="transcription services, researchers, interviews, lectures, fast transcription, accurate transcription, google scholar" />
        <link rel="canonical" href="https://www.sonictranscript.com" />
        <link rel="icon" href="https://www.sonictranscript.com/favicon.ico" /> {/* Add this line */}
      </Helmet>
        <div className='bg__normal'>
            <Navbar />
            <Header />
        </div>
        <TabBox />
        <WhatGPT3 />
        <Footer />
    </div>
  )
}

export default Home